.login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  overflow: auto;

  .logo {
    display: block;
    margin: 10px auto;
  }

  &.registration {
    justify-content: flex-start;
  }

  .btn-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__form {
    width: 100%;
    min-width: 400px;
    padding: 2rem;
    box-shadow: 0px 1px 2px rgba(36, 80, 141, 0.2);
    border-radius: 5px;
    background-color: white;
    margin: auto;

    &--title {
      margin: 0 0 1rem;
    }
  }
}
